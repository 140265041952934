import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import QuibbleTable from 'components/ReusableComponents/Table/QuibbleTable';
import { BodyText } from 'components/ReusableComponents/Text/Text';
import { isEmpty } from 'lodash';
import { Link } from "react-router-dom";
import RenderBar from '../../sharedComponents/MarketPropertyTable/RenderBar';
import ExtendRenderBar from '../../sharedComponents/MarketPropertyTable/ExtendRenderBar';
import StarRating from '../../../../components/ReusableComponents/Rating/StarRatings';
import { TableAnalyticsDiv } from '../../sharedComponents/CommonCSS';
import { useSelector } from 'react-redux';
import AnalyticsFilter from '../../sharedComponents/AnalyticsFilter/AnalyticsFilter';

const GroupTableBody = styled.div`
  width: 100%;
  max-width: 1800px;
  height: fit-content;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  gap: ${ ({ gap }) => gap };
  box-sizing: border-box;
`;


const GroupTableParentDiv = styled.div`
  width: 100%;
  height: fit-content;
  max-width: 1800px;
  margin: 0 0 0 0;
`;

const NameLink = styled(BodyText)`
  margin: 0px;

  &:hover {
    color: ${ ({ theme }) => theme.quibbleBlue };
  }
`;

const ExtendNameLink = styled(NameLink)`
  color: ${ ({ theme }) => theme.expandedTableFont };
`;

const ExportButtonContainer = styled.div`
  //margin-left: 13%;
  margin-bottom: 10px;
`

const GroupAnalyticsTable = ({ monthYear, searchString, showFilter }) => {

  const { propertyGroupAnalytics, isFetchingPropertyGroupAnalytics } = useSelector(({ property }) => property);

  const [activeFilter, setActiveFilter] = useState('');
  const [checkedValues, setCheckedValues] = useState([]);
  const [filterState, setFilterState] = useState({});

  const barDivs = showFilter ? '200px' : '286px';
  const tableDiv = showFilter ? '1310px' : '1585px';

  const GroupAnalyticsColumn = [
    {
      label: 'property',
      width: '360px',
      align: 'left',
      customRender: (data) => (
        <Link
          to={{
            pathname: "/market-analytics/group-details",
            state: {
              date: monthYear,
              groupId: data?.propertyGroup?._id,
            },
          }}
        >
          <NameLink>{data?.key}</NameLink>
        </Link>
      ),
      extendDataIndex: 'key',
      extendCustomRender: (data) => <ExtendNameLink>{data}</ExtendNameLink>,
    },
    {
      label: 'occupancy',
      width: barDivs,
      align: 'left',
      customRender: (data) => (
        <RenderBar
          tooltipLabel="Occupancy"
          current={data?.currentYearOccupancy}
          forecast={data?.forecastOccupancy}
          market={data?.marketOccupancy}
          type="occupancy"
        />
      ),
      extendDataIndex: 'lastYearOccupancy',
      extendCustomRender: (data) => <ExtendRenderBar data={data} type="occupancy" />,
    },
    {
      label: 'occupancy gap',
      width: '148px',
      align: 'right',
      dataIndex: 'currentYearOccupancyGap',
      customRender: (data) => `${(data * 100).toFixed(0)}%`,
    },
    {
      label: 'adr',
      width: '100px',
      align: 'left',
      customRender: (data) => (
        <RenderBar
          tooltipLabel="ADR"
          current={data?.currentYearAdr}
          forecast={data?.forecastAdr}
        />
      ),
      extendCustomRender: (data) => (
        <ExtendRenderBar 
          data={data?.lastYearAdr} 
          maxValue={data?.forecastAdr} 
        />
      ),
    },
    {
      label: 'revenue',
      width: barDivs,
      align: 'left',
      customRender: (data) => (
        <RenderBar
          tooltipLabel="Revenue"
          current={data?.currentYearTotalRevenue}
          forecast={data?.forecastRevenue}
        />
      ),
      extendCustomRender: (data) => (
        <ExtendRenderBar
          data={data?.lastYearTotalRevenue}
          maxValue={data?.forecastRevenue}
        />
      ),
    },
    {
      label: 'reviews',
      align: 'left',
      customRender: (data) => (
        <StarRating
          yellowLabel={data.averageMarketRating}
          purpleLabel={data.averageRating}
        />
      ),
    },
    {
      label: 'blocked days',
      align: 'right',
      dataIndex: 'currentYearBlockedDaysShare',
      customRender: (data) => `${(data * 100).toFixed(0)}%`,
      extendDataIndex: 'lastYearBlockedDaysShare',
      extendCustomRender: (data) => `${(data * 100).toFixed(0)}%`,
    },
  ];  

  const onCheckBoxValues = useCallback((values) => {
    setCheckedValues(values);
  }, []);

  const filterPropertyAnalytics = useMemo(() => {
    let filteredListings = propertyGroupAnalytics.filter(
      (item) => item.propertyGroup?.type === "Group"
    );
  
    if (searchString) {
      filteredListings = filteredListings.filter((data) => 
        data?.key?.toLowerCase().includes(searchString.toLowerCase()) || 
        data?.propertyGroup?.name?.toLowerCase().includes(searchString.toLowerCase())
      );
    }
  
    return filteredListings;
  }, [propertyGroupAnalytics, searchString]);

  const onFilterChange = useCallback((value) => {
    setFilterState(value);
  }, []);

  const appliedFilter = useMemo(() => {

    if (isEmpty(filterState)) {
      return filterPropertyAnalytics;
    }
    ;

    const isNoRating = !filterState.rating.length || filterState.rating.length === 5;


    const ratingFilter = isNoRating ? filterPropertyAnalytics : filterState.rating.reduce((output, current) => {
      const filterRating = filterPropertyAnalytics.filter((data) => (data.averageRating <= current && data.averageRating > current - 1))
      return [...output, ...filterRating]
    }, []);

    const bedroomFilter = (data) => {
      if (filterState.bedrooms.length === 1 && filterState.bedrooms[0] === 0) {
        return true;
      }

      const bedroomCount = parseInt(data.bedrooms, 10);
      if (filterState.bedrooms.includes(5)) {
        return bedroomCount >= 5 || filterState.bedrooms.includes(bedroomCount);
      }
      return filterState.bedrooms.includes(bedroomCount);
    };


    return ratingFilter.filter((data) => {
      const dataOccupancy = data?.currentYearOccupancy ? data?.currentYearOccupancy * 100 : 0;
      const dataADR = data?.currentYearAdr ? data?.currentYearAdr : 0;
      const dataRevenue = data?.currentYearTotalRevenue ? data?.currentYearTotalRevenue : 0;
      const dataBlockedDays = data?.currentYearBlockedDaysShare ? data?.currentYearBlockedDaysShare * 100 : 0;
      const dataAverageRating = data?.averageRating ? data?.averageRating : 0;

      const occupancyFilter = dataOccupancy >= filterState.occupancy[0] && dataOccupancy <= filterState.occupancy[1];
      const adrFilter = dataADR >= filterState.adr[0] && dataADR <= filterState.adr[1];
      const revenueFilter = dataRevenue >= filterState.revenue[0] && dataRevenue <= filterState.revenue[1];
      const blockedDaysFilter = dataBlockedDays >= filterState.blockedDays[0] && dataBlockedDays <= filterState.blockedDays[1];
      const ratingFilter = filterState?.rating.length ? dataAverageRating <= Math.max.apply(Math, filterState.rating) : true;

      return occupancyFilter && adrFilter && revenueFilter && blockedDaysFilter && ratingFilter && bedroomFilter(data);

    });
  }, [filterState, filterPropertyAnalytics]);

  const filteredProperties = useMemo(() => {
    return {
      stars: appliedFilter.filter((data) => data?.quadrant === 'star'),
      overpriced: appliedFilter.filter((data) => data?.quadrant === 'overpriced'),
      underpriced: appliedFilter.filter((data) => data?.quadrant === 'underpriced'),
      productIssues: appliedFilter.filter((data) => data?.quadrant === 'issue'),
    }
  }, [appliedFilter]);
  const tableData = filteredProperties[activeFilter] || appliedFilter;

  return (
    <GroupTableBody gap={ showFilter ? '16px' : 'unset' }>
      <AnalyticsFilter isFilterVisible={ showFilter } onFilterChange={ onFilterChange }/>
      <GroupTableParentDiv>
        <TableAnalyticsDiv width={ tableDiv }>
          <QuibbleTable columns={ GroupAnalyticsColumn } data={ tableData } checkedRows={ checkedValues }
                        expandable={ true } withCheckBox={ true } pagination={ true } paginationSize={ 10 }
                        isLoading={ isFetchingPropertyGroupAnalytics } onCheckBoxChange={ onCheckBoxValues }
                        monthYear={ monthYear } columnFilter={ true } type="property" persistPage={ true }/>
        </TableAnalyticsDiv>
      </GroupTableParentDiv>
    </GroupTableBody>
  )
}


export default GroupAnalyticsTable;
