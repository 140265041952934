import React from 'react';
import { BodyTitle } from '../../../../../../../components/ReusableComponents/Text/Text';

const PriceSage = () => {
  return (
    <BodyTitle>
      Price Sage
    </BodyTitle>
  )
}

export default PriceSage;