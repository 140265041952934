import React, { useContext, memo } from 'react';
import styled from 'styled-components';
import { PriceAdjustContext } from '../../../../PriceAdjustment';
import { getMultiplier, getOverridePrice } from '../../../../components/utils';
import { currencyFormatter } from '../../../../../../util/helper';
import { Tooltip } from 'antd';
import { BodyText } from '../../../../../../components/ReusableComponents/Text/Text';

const OverrideSpan = styled.span`
  color: #FFA800;
  font-style: italic;
  font-weight: 700;
`;

const TooltipItem = styled(BodyText)`
  color: white;
`;

const OptPriceGap = ({value, mode="optimize"}) => {
  const {priceAdjust, adjustmentMenu, activeMarket, activeProperty, activeGroup} = useContext(PriceAdjustContext);

  const multiplier = getMultiplier(priceAdjust,activeMarket, activeGroup, activeProperty, adjustmentMenu, value)

  let optPrice = value.optimizedPrice;
  let priceGap = value.priceGap;

  if (optPrice) {
    const multiplierPercent  = (Math.abs(multiplier) > 0 ? multiplier : 0) / 100;
    const addValue = Number(optPrice * multiplierPercent);
    optPrice  = optPrice + addValue;

    if (value?.maxPrice && (optPrice > value?.maxPrice)) {
      optPrice = value?.maxPrice;
    };
  
    if (value?.minPrice && (optPrice < value?.minPrice)) {
      optPrice = value?.minPrice;
    };
  
    priceGap = optPrice - value.price;
  }

  if (!optPrice && value?.maxPrice) {
    optPrice = value?.maxPrice;
  }

  const overridePrice = getOverridePrice(priceAdjust, activeProperty, value);

  const overridePriceItem = overridePrice || value.override;

  if (overridePriceItem) {
    priceGap = optPrice - overridePriceItem;
  };

  //display opt price hierarchy
  let showOptPrice = '-';
  
  if (optPrice) {
    showOptPrice = currencyFormatter(optPrice);
  };

  

  if (value?.faroutPrice &&  optPrice < value?.faroutPrice) {
    showOptPrice = <Tooltip color='black'  showArrow={false} title={<TooltipItem> Set by Far out Price </TooltipItem>}> <OverrideSpan>{currencyFormatter(value?.faroutPrice)}</OverrideSpan> </Tooltip>
  }

  if (overridePriceItem) {
    showOptPrice = <Tooltip color='black'  showArrow={false} title={<TooltipItem> Set by Override </TooltipItem>}> <OverrideSpan>{currencyFormatter(overridePriceItem)}</OverrideSpan> </Tooltip>
  };
  

  const modeReturn = {
    optimize: showOptPrice,
    gap: priceGap ? priceGap < 0 ? `-${currencyFormatter(Math.abs(priceGap))}`: currencyFormatter(priceGap) :  '-' 
  }

  return (
    <>
     {modeReturn[mode]}
    </>
  )
}

export default memo(OptPriceGap);