import React, { useState, useCallback, useEffect } from 'react'
import styled from 'styled-components';
import {message} from 'antd'
import EventHeader from './components/EventHeader'
import Events from './components/Events'
import EventsDetailsTable from './components/EventsDetailsTable';
import PriceAdjustmentMultiplier from './PriceAdjustmentMultiplier';
import { findIndex, isNil } from 'lodash';
import { property as propertyService } from '../../../services/property';
import { events as eventsService } from '../../../services/events';

const OverallDiv = styled.div`
  margin: 20px;
  font-family: Commissioner;
`;

const OverviewBody = styled.div`
  display: flex;
  gap: 20px;
`;

const EventPricing = () => {
  const [searchString, setSearchString] = useState('');
  const [selectedEvents, setSelectedEvents] = useState([]);
  const [multiplier, setMultiplier] = useState(0);
  const [payload, setPayload] = useState({ markets: [] });
  const[multiplierLoading, setMultiplierLoading] = useState(false);
  // const [messageApi, contextHolder] = message.useMessage();



  useEffect(() => {
    let updatedPayload = { markets: []}
    

    if (selectedEvents?.length > 0) {
      for (const s of selectedEvents) {
        if(s) {
        const markets = s.markets;
        let eventMultiplier = null;
        let sMultiplier = s.multiplier ?? 0;

        if (!isNil(multiplier)) {
          eventMultiplier = sMultiplier + multiplier;
        }

        const calendar = {
          startDate: s.startDate.substring(0, 10),
          endDate: s.endDate.substring(0, 10),
          eventMultiplier: eventMultiplier,
        }

        for (const m of markets) {
          const marketIndex = findIndex(updatedPayload?.markets, p => p.name === m);
          if (marketIndex > -1) {
            updatedPayload.markets[marketIndex].calendar.push(calendar);
          } else {
            updatedPayload.markets.push({ name: m, calendar: [calendar] });
          }
        }
      }
    }
      setPayload(updatedPayload)
    }
  }, [multiplier, selectedEvents]);

  const setMultiplierValue = useCallback((value) => {
    setMultiplier(value);
  }, [setMultiplier]);

  const handleSelectEvent = useCallback((events) => {
    setSelectedEvents(events);
  }, [setSelectedEvents]);

  const saveMultiplier = async () => {
    for (let e of selectedEvents) {
      const eMultiplier = e.multiplier ?? 0;
      let mltplr = null;

      if (!isNil(multiplier)) {
        mltplr = eMultiplier + multiplier;
      }

      await eventsService.updateEventDetails({
        eventId: e._id,
        apply: false,
        body: {
          multiplier: mltplr,
        }
      })
    }
  }
 
  const handleOnSave = async () => {
    try {
      setMultiplierLoading(true);
      message.loading({ content: 'Saving changes...', key: 'saveLoading' });

      await saveMultiplier();
      await propertyService.applyRules(payload, false);
  
      setMultiplierLoading(false); 
      message.success({ content: 'Changes saved successfully!', key: 'saveLoading', duration: 2 }); 
      window.location.reload();
    } catch (e) {
      setMultiplierLoading(false); 
      console.error(e);
      message.error({ content: 'Failed to save changes.', duration: 2 }); 
    }
  };

  const handleOnSaveAndSend = async () => {
    try {
      setMultiplierLoading(true);
      message.loading({ content: 'Saving and sending changes...', key: 'saveSendLoading' });

      await saveMultiplier();
      await propertyService.applyRules(payload, true);
  
      setMultiplierLoading(false); 
      message.success({ content: 'Changes saved and sent successfully!', key: 'saveSendLoading', duration: 2 }); 
      window.location.reload();
    } catch (e) {
      setMultiplierLoading(false);
      console.error(e);
      message.error({ content: 'Failed to save and send changes.', duration: 2 });
    }
  };

  return (
    <OverallDiv>

      <EventHeader setSearchString={setSearchString} />
      <Events searchString={searchString}/>

      <OverviewBody>
        <EventsDetailsTable searchString={searchString} onSelectEvent={handleSelectEvent} multiplier={multiplier} multiplierLoading = {multiplierLoading}/>
        <PriceAdjustmentMultiplier
          onMultiplierChange={setMultiplierValue}
          onSave={handleOnSave}
          onSaveAndSend={handleOnSaveAndSend}
          disabled={selectedEvents?.length === 0} 
        />
      </OverviewBody>

    </OverallDiv>
  )
}

export default EventPricing
