import React, { useEffect, useCallback } from 'react';
import styled from 'styled-components';
import Optimize from '../Optimization/Optimize';
import MarketDescription from '../../marketAnalytics/MarketDetails/components/MarketDescription';
import { useDispatch, useSelector } from 'react-redux';
import PropertyDescription from '../../marketAnalytics/PropertyDetails/components/PropertyDescription';
import {
  getPortfolioPricing,
  getPortfolioPricingRules,
  // getMarketPricing,
  // getMarketPricingRules,
  // getPropertyPricing,
  // getPropertyPricingRules,
  // getPropertyGroupPricing,
  // getPropertyGroupPricingRules,
} from '../../../appRedux/actions';
import GroupDescription from '../../marketAnalytics/GroupDetails/components/GroupDescription';

const PriceAdjustmentMenu = styled.div`
  width: 100%;
  height: 97%;
  margin: -16px 0 0 0;
  gap: 16px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const PortfolioAdjustmentGap = styled.span`
  width: 100%;
  margin: 6px;
  height: 0px;
`;

const AdjustmentMenu = ({ adjustmentMenu, propertyId, monthYear, setMonthYear, isProperty, activeMarket, activeProperty, activeGroup }) => {
  const dispatch = useDispatch();

  
    // const {
    //   portfolioPricing,
    //  } = useSelector(({ property, markets }) => ({...property, ...markets}));

  
  useEffect(() => {
    if (monthYear && adjustmentMenu === 'portfolio') {
      const month = monthYear.getMonth() + 1;
      const year =  monthYear.getFullYear();
      dispatch(getPortfolioPricing({ month, year,}));
      dispatch(getPortfolioPricingRules());
    }
  }, [monthYear, dispatch, adjustmentMenu])

  // useEffect(() => {
  //   const month = monthYear.getMonth() + 1;
  //   const year = monthYear.getFullYear();

  //   if (!portfolioPricing.length) {
  //     dispatch(getPortfolioPricing({ month, year }));
  //     dispatch(getPortfolioPricingRules());
  //   }

  //   if (activeMarket) {
  //     dispatch(getMarketPricing({ month, year, market: activeMarket }));
  //     dispatch(getMarketPricingRules({ params: { market: activeMarket } }));
  //   }

  //   if (activeGroup) {
  //     dispatch(getPropertyGroupPricing({ month, year, propertyGroupId: activeGroup }));
  //     dispatch(getPropertyGroupPricingRules({ params: { propertyGroupId: activeGroup } }));
  //   }

  //   if (propertyId) {
  //     dispatch(getPropertyPricing({ month, year, propertyId }));
  //     dispatch(getPropertyPricingRules({ params: { listingId: propertyId } }));
  //   }
  // }, [monthYear, dispatch, portfolioPricing, activeMarket, activeGroup, propertyId]);

  const { propertyDetails, isFetchingPropertyDetails } = useSelector(({ property }) => property);

  const setMonthYearVal = useCallback((value) => {
    setMonthYear(value);
  }, [setMonthYear]);

  return (
    <PriceAdjustmentMenu>
      {(adjustmentMenu === 'portfolio' || (adjustmentMenu === 'market' && !activeMarket) || (adjustmentMenu === 'property' && !activeProperty) || (adjustmentMenu === 'group' && !activeGroup)) ? (
        <PortfolioAdjustmentGap />
      ) : null}

      {adjustmentMenu === 'market' && activeMarket && (
        <MarketDescription monthYear={monthYear} />
      )}

      {adjustmentMenu === 'group' && activeGroup && (
        <GroupDescription monthYear={monthYear} />
      )}

      {adjustmentMenu === 'property' && activeProperty && !isProperty && (
        <PropertyDescription
          isFetchingPropertyDetails={isFetchingPropertyDetails}
          propertyDetails={propertyDetails}
          propertyId={propertyId}
          monthYear={monthYear}
        />
      )}

      <Optimize setMonthYear={setMonthYearVal} />
    </PriceAdjustmentMenu>
  );
};

export default AdjustmentMenu;
