import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { Button, Popover, Input } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { LayoutBody } from 'components/ReusableComponents/Layout/LayoutBody';
import { darkScrollbar, lightScrollbar } from '../../../components/ReusableComponents/Scrollbar/Scrollbar';
import MarketPropertyFilter from '../sharedComponents/MarketPropertyFilter/MarketPropertyFilter';
import PropertyAnalyticsTable from './components/PropertyAnalyticsTable';
import * as XLSX from 'xlsx';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { now } from '../../../constants/GlobalConstant';
import { PrimaryButton, SecondaryButton } from '../../../components/ReusableComponents/Button/Button';

const PropertyBody = styled(LayoutBody)`  
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  height: calc(100vh - 72px);
  gap: 16px;
  ${({ theme }) => (theme.isDefault ? lightScrollbar : darkScrollbar)};
`;

const FilterDiv = styled.div`
  display: flex;
  width: 100%;
`;

const ExportButtonContainer = styled.div`
  display: flex;
  margin: 0 8px;
  align-items: center;
`;

const DownloadIconButton = styled(Button)`
  margin-bottom: 0;
  background-color: transparent;
  color: ${({ isActive, theme }) => (isActive ? theme.white : 'inherit')};
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: ${({ theme }) => theme.quibbleBlue};
    color: ${({ theme }) => theme.white};
  }

  &:active {
    background-color: ${({ theme }) => theme.quibbleBlue};
    color: ${({ theme }) => theme.white};
  }

  &:focus {
    background-color: ${({ theme }) => theme.quibbleBlue};
    color: ${({ theme }) => theme.white};
  }
`;

const FilenameContainer = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  padding: 4px 11px;
  width: 100%;
`;

const FileExtension = styled.span`
  margin-left: 4px;
  color: rgba(0, 0, 0, 0.45);
  white-space: nowrap;
  user-select: none;
`;

const PopoverContentButton = styled.div`
  display: flex;
  margin-top: 16px;
  width: 100%;
`;

const DownloadButton = styled(PrimaryButton)`
  flex: 1;
  height: 50px;
`;  

const CancelButton = styled(SecondaryButton)`
  flex: 1;
  height: 50px;
`; 

const Property = () => {
  const { propertyAnalytics } = useSelector(({ property }) => property);
  const [monthYear, setMonthYear] = useState(now);
  const [searchString, setSearchString] = useState('');
  const [showFilter, setShowFilter] = useState(false);
  const [dynamicPricing, setDyanmicPricing] = useState(false);
  const [fileName, setFileName] = useState(`Property-${moment().format('MM-DD-YYYY')}`);
  const [popoverVisible, setPopoverVisible] = useState(false);

  const setMonthYearVal = useCallback((value) => {
    setMonthYear(value);
  }, []);

  const setSearchStringFunc = useCallback((value) => {
    setSearchString(value);
  }, []);

  const onFilterToggle = useCallback(() => {
    setShowFilter((prev) => !prev);
  }, []);

  const exportToExcel = (data) => {
    const flattenProperties = (properties) => {
      let result = [];
      properties.forEach(property => {
        result.push({
          property: property.key,
          occupancy: property.currentYearOccupancy,
          "forecast Occupancy": property.forecastOccupancy,
          "occupancy Gap": property.currentYearOccupancyGap?.toFixed?.(2),
          adr: property?.currentYearAdr?.toFixed?.(2) || property?.currentYearAdr,
          "forecast Adr": property?.forecastAdr?.toFixed?.(2),
          revenue: property.currentYearTotalRevenue,
          "forecast Revenue": property.forecastRevenue,
          reviews: property.averageRating,
          "blocked days": property.currentYearBlockedDaysShare,
        });
    
        if (property.nestedProperties && property.nestedProperties.length > 0) {
          result = result.concat(flattenProperties(property.nestedProperties));
        }
      });
      return result;
    };
    
    const worksheetData = flattenProperties(data);

    const worksheet = XLSX.utils.json_to_sheet(worksheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Properties Data');

    XLSX.writeFile(workbook, `${fileName}.xlsx`);
  };

  const handlePopoverConfirm = () => {
    setPopoverVisible(false);
    exportToExcel(propertyAnalytics);
  };

  const downloadPopoverContent = (
    <div>
      <FilenameContainer>
        <Input
          value={fileName}
          onChange={(e) => setFileName(e.target.value)}
          placeholder="Enter file name"
          bordered={false}
          style={{ flex: 1 }}
        />
        <FileExtension>.xls</FileExtension>
      </FilenameContainer>
      <PopoverContentButton>
        <CancelButton onClick={() => setPopoverVisible(false)} style={{ marginRight: 8 }}>
          Cancel
        </CancelButton>
        <DownloadButton onClick={handlePopoverConfirm}>Download</DownloadButton>
      </PopoverContentButton>
    </div>
  );

  return (
    <PropertyBody>
      <FilterDiv>
        <MarketPropertyFilter
          dynamicPricing={dynamicPricing}
          setDyanmicPricing={setDyanmicPricing}
          monthYear={monthYear}
          setMonthYearVal={setMonthYearVal}
          setSearchStringFunc={setSearchStringFunc}
          isProperty={true}
          onFilterToggle={onFilterToggle}
          showFilter={showFilter}
        />
        <ExportButtonContainer>
          <Popover
            content={downloadPopoverContent}
            title="Filename"
            trigger="click"
            placement="bottomRight"
            arrow="hide"
            open={popoverVisible}
            onOpenChange={setPopoverVisible}
          >
            <DownloadIconButton type="text" shape="circle" icon={<DownloadOutlined />} />
          </Popover>
        </ExportButtonContainer>
      </FilterDiv>
      <PropertyAnalyticsTable
        dynamicPricing={dynamicPricing}
        monthYear={monthYear}
        searchString={searchString}
        showFilter={showFilter}
      />
    </PropertyBody>
  );
};

export default Property;
