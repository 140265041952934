import React from 'react';
import { TreeSelect, Button, Input, Checkbox, Upload, message } from 'antd';
import { PlusOutlined, DeleteOutlined, UploadOutlined, FileImageOutlined, UngroupOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { PrimaryButton, SecondaryButton } from '../../../components/ReusableComponents/Button/Button';
import { property } from '../../../services/property';

const { Dragger } = Upload;

const ComponentContainer = styled.div`
  height: calc(100vh - 240px);
  display: flex;
  flex-direction: column; 
  justify-content: space-between;
  padding: 30px;
  background: #fff;
`;

const NewGroup = styled.div`
  width: 50%;
  flex-grow: 1;
  overflow-y: auto;
`;

const ImageUploader = styled.div`
  position: relative;
  width: 120px;
  height: 80px;
  border: 1px solid #E8E8E8;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;

  .ant-upload.ant-upload-drag {
    border: none;
    background: none;
  }
`;

const UploadedImagePreview = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ImageOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.5s;

  ${ImageUploader}:hover & {
    opacity: 1;
  }
`;

const UploadIcon = styled(UploadOutlined)`
  font-size: 24px;
  color: #fff;
`;

const NewGroupTitle = styled.h1`
  font-family: Commissioner;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
`;

const NewGroupField = styled.div`
  width: 100%;
  margin-top: 10px;

  div {
    margin-bottom: 10px;
  }
`;

const ChildPropertiesDiv = styled.div`
  padding-left: 0;
  margin-left: 50px;
`;

const AddChildButton = styled(Button)`
  border: none;
  color: #1bb3cd;

  &:hover {
    background: #e6f7ff;
    color: #1890ff;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 20px;

  button {
    margin-left: 10px;
  }
`;

const SaveButton = styled(PrimaryButton)`
  width: 120px;
  height: 50px;
  margin: 16px 0 0 0;
`;

const CancelButton = styled(SecondaryButton)`
  width: 120px;
  height: 50px;
  margin: 16px 0 0 0;
`;

const BlueLineWrapper = styled.div`
  position: absolute;
  left: -30px;
  top: -50px;
  width: 30px;
  height: 67.5px;
  border-left: 1px solid #99E7EE;
  border-bottom: 1px solid #99E7EE;
`;

const OrangeLineWrapper = styled.div`
  position: absolute;
  left: -30px;
  top: 15px;
  width: 30px;
  height: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  &::before {
    content: '';
    width: 10px;
    height: 10px;
    background-color: #FCA584;
    border-radius: 50%;
  }

  &::after {
    content: '';
    flex-grow: 1;
    width: 2px;
    background-color: #FCA584;
    display: ${(props) => (props.isLastChild ? 'none' : 'block')};
  }
`;

export const NestedComponent = ({
  parentId,
  childProperties,
  listingsMap,
  propertyGroupsListings,
  onParentChange,
  onChildChange,
  onAddChild,
  onDeleteChild,
  onCancel,
  onSave,
  onUngroup,
  isEditMode,
}) => {
  const getPropertyNameById = (id, listings) => {
    const property = listings.find((item) => item.id === id);
    return property ? property.name : '';
  };

  const prepareTreeData = (listings, selectedIds, parentProperty, parentId) => {
    const sortedListings = listings.sort((a, b) => {
        const marketA = `${a.city}, ${a.state}`;
        const marketB = `${b.city}, ${b.state}`;
        return marketA.localeCompare(marketB);
    });

    const groupedByMarket = sortedListings.reduce((acc, item) => {
        const market = `${item.city}, ${item.state}`;
        const { id, name } = item;

        const isParent = id === parentId;
        const isDisabled = selectedIds.includes(id) || isParent;

        const displayTitle = isParent
            ? `${name} (Already selected as parent property)`
            : selectedIds.includes(id)
            ? `${name} (Already part of ${parentProperty})`
            : name;

        if (!acc[market]) acc[market] = [];
        acc[market].push({
            title: displayTitle,
            originalTitle: name,
            key: id,
            value: id,
            disabled: isDisabled,
        });
        return acc;
    }, {});

    return Object.entries(groupedByMarket).map(([market, children]) => ({
        title: market,
        key: market,
        value: market,
        selectable: false,
        children,
    }));
};

const parentProperty = getPropertyNameById(parentId, propertyGroupsListings);

const treeData = prepareTreeData(propertyGroupsListings, childProperties, parentProperty, parentId);

  return (
    <ComponentContainer>
      <NewGroup>
        <NewGroupTitle>Nested Property</NewGroupTitle>
        <NewGroupField>
          <div>Parent Property</div>
          <TreeSelect
            value={listingsMap[parentId] || parentId}
            onChange={onParentChange}
            style={{ width: '100%' }}
            placeholder="Select Parent Property"
            treeData={treeData}
            treeDefaultExpandAll={false}
            treeNodeLabelProp="originalTitle"
          />
        </NewGroupField>

        <ChildPropertiesDiv>
          <NewGroupField>
            <div>Child</div>
            {childProperties.map((child, index) => (
              <div
                key={index}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  position: 'relative',
                }}
              >
                <BlueLineWrapper />
                <TreeSelect
                  value={listingsMap[child] || child}
                  onChange={(value) => onChildChange(value, index)}
                  style={{ width: '90%', margin: 0 }}
                  placeholder="Select Child Property"
                  treeData={treeData}
                  treeDefaultExpandAll={false}
                  treeNodeLabelProp="originalTitle"
                />
                <Button
                  type="text"
                  icon={<DeleteOutlined />}
                  onClick={() => onDeleteChild(index)}
                />
              </div>
            ))}
            <div style={{ display: 'flex', gap: '10px' }}>
              <AddChildButton type="text" onClick={onAddChild}>
                <PlusOutlined style={{ marginRight: '10px' }} /> Child
              </AddChildButton>
              {isEditMode && (
                <Button type="text" onClick={onUngroup}>
                  <UngroupOutlined /> Ungroup
                </Button>
              )}
            </div>
          </NewGroupField>
        </ChildPropertiesDiv>
      </NewGroup>
      <ButtonContainer>
        <CancelButton onClick={onCancel}>Cancel</CancelButton>
        <SaveButton onClick={onSave}>Save</SaveButton>
      </ButtonContainer>
    </ComponentContainer>
  );
};

export const GroupComponent = ({
  groupName,
  setGroupName,
  sameBuilding,
  setSameBuilding,
  uploadedImage,
  setUploadedImage,
  childProperties,
  listingsMap,
  propertyGroupsListings,
  onChildChange,
  onAddChild,
  onDeleteChild,
  onCancel,
  onSave,
  onUngroup,
  isEditMode,
}) => {
  const prepareTreeData = (listings, selectedIds) => {
    const sortedListings = listings.sort((a, b) => {
        const marketA = `${a.city}, ${a.state}`;
        const marketB = `${b.city}, ${b.state}`;
        return marketA.localeCompare(marketB);
    });

    const groupedByMarket = sortedListings.reduce((acc, item) => {
        const market = `${item.city}, ${item.state}`;
        const { id, name } = item;
        const isDisabled = selectedIds.includes(id);
        const displayTitle = isDisabled ? `${name} (Already part of ${groupName})` : name;

        if (!acc[market]) acc[market] = [];
        acc[market].push({
            title: displayTitle,
            originalTitle: name,
            key: id,
            value: id,
            disabled: isDisabled,
        });
        return acc;
    }, {});

    return Object.entries(groupedByMarket).map(([market, children]) => ({
        title: market,
        key: market,
        value: market,
        selectable: false,
        children,
    }));
  };

  const treeData = prepareTreeData(propertyGroupsListings, childProperties);

  const handleFileUpload = async (file) => {
    try {
      const response = await property.uploadGroupImage(file);
      if (response?.data?.url) {
        setUploadedImage(response.data.url);
        message.success("Image uploaded successfully");
      } else {
        message.error("Failed to upload image");
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      message.error("Error uploading image");
    }
    return false;
  };

  return (
    <ComponentContainer>
      <NewGroup>
        <NewGroupTitle>Group Property</NewGroupTitle>
        <NewGroupField>
          <div>Group Image</div>
          <ImageUploader>
            {uploadedImage ? (
              <>
                <UploadedImagePreview src={uploadedImage} alt="Uploaded Group" />
                <ImageOverlay onClick={() => document.getElementById("uploadTrigger")?.click()}>
                  <UploadIcon />
                </ImageOverlay>
              </>
            ) : null}

            <Dragger
              name="file"
              multiple={false}
              beforeUpload={handleFileUpload}
              showUploadList={false}
              id="uploadTrigger"
              style={{ display: uploadedImage ? "none" : "block" }}
            >
              <FileImageOutlined style={{ fontSize: "32px", color: "#888" }} />
              <div>
                <UploadOutlined />
                Upload
              </div>
            </Dragger>
          </ImageUploader>
        </NewGroupField>
        <NewGroupField>
          <div>Group Name</div>
          <Input
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
            placeholder="Enter group name"
          />
        </NewGroupField>
        <NewGroupField>
          <Checkbox
            checked={sameBuilding}
            onChange={(e) => setSameBuilding(e.target.checked)}
          >
            These are units located in the same building.
          </Checkbox>
        </NewGroupField>
        <ChildPropertiesDiv>
          <NewGroupField>
            <div>Child</div>
            {childProperties.map((child, index) => (
              <div
                key={index}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  position: 'relative',
                }}
              >
                {sameBuilding && <OrangeLineWrapper isLastChild={index === childProperties.length - 1} />}
                <TreeSelect
                  value={listingsMap[child] || child}
                  onChange={(value) => onChildChange(value, index)}
                  style={{ width: '90%', margin: 0 }}
                  placeholder="Select Child Property"
                  treeData={treeData}
                  treeDefaultExpandAll={false}
                  treeNodeLabelProp="originalTitle"
                />
                <Button
                  type="text"
                  icon={<DeleteOutlined />}
                  onClick={() => onDeleteChild(index)}
                />
              </div>
            ))}
            <div style={{ display: 'flex', gap: '10px' }}>
              <AddChildButton type="text" onClick={onAddChild}>
                <PlusOutlined style={{ marginRight: '10px' }} /> Unit
              </AddChildButton>
              {isEditMode && (
                <Button type="text" onClick={onUngroup}>
                  <UngroupOutlined /> Ungroup
                </Button>
              )}
            </div>
          </NewGroupField>
        </ChildPropertiesDiv>
      </NewGroup>
      <ButtonContainer>
        <CancelButton onClick={onCancel}>Cancel</CancelButton>
        <SaveButton onClick={onSave}>Save</SaveButton>
      </ButtonContainer>
    </ComponentContainer>
  );
};
