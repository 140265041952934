import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import GroupAnalyticsTable from './components/GroupAnalyticsTable';
import { now } from '../../../constants/GlobalConstant';
import { useSelector } from 'react-redux';
import MarketPropertyFilter from '../sharedComponents/MarketPropertyFilter/MarketPropertyFilter';
import { LayoutBody } from 'components/ReusableComponents/Layout/LayoutBody';
import { darkScrollbar, lightScrollbar } from 'components/ReusableComponents/Scrollbar/Scrollbar';

const PropertyBody = styled(LayoutBody)`  
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  height: calc(100vh - 72px);
  gap: 16px;
  ${({ theme }) => (theme.isDefault ? lightScrollbar : darkScrollbar)};
`;

const FilterDiv = styled.div`
  display: flex;
  width: 100%;
`;

const Group = () => {
  const [monthYear, setMonthYear] = useState(now);
  const [searchString, setSearchString] = useState('');
  const [showFilter, setShowFilter] = useState(false);

  const setMonthYearVal = useCallback((value) => {
    setMonthYear(value);
  }, []);

  const setSearchStringFunc = useCallback((value) => {
    setSearchString(value);
  }, []);

  const onFilterToggle = useCallback(() => {
    setShowFilter((prev) => !prev);
  }, []);

  return (
    <PropertyBody>
      <FilterDiv>
        <MarketPropertyFilter
          monthYear={monthYear}
          setMonthYearVal={setMonthYearVal}
          setSearchStringFunc={setSearchStringFunc}
          isPropertyGroup={true}
          onFilterToggle={onFilterToggle}
          showFilter={showFilter}
        />
      </FilterDiv>
      <GroupAnalyticsTable 
        monthYear={monthYear}
        searchString={searchString}
        showFilter={showFilter}
      />
    </PropertyBody>
  );
};

export default Group;
