import { isUndefined } from "lodash";

export const getOverrideMinstay = (priceAdjust, level, activeMarket, activeGroup, activeProperty, data )  => {

  const ifProMinOver =  priceAdjust.property[activeProperty] && priceAdjust.property[activeProperty][data?.date] &&  priceAdjust.property[activeProperty][data?.date].minStay;
  const ifMarPropertyMinOver = priceAdjust.market[data?.market] && priceAdjust.market[data?.market][data?.date] &&  priceAdjust.market[data?.market][data?.date].minStay;
  const ifMarMinOver = priceAdjust.market[activeMarket] && priceAdjust.market[activeMarket][data?.date] &&  priceAdjust.market[activeMarket][data?.date].minStay;
  const ifPorfMinOver = priceAdjust.portfolio && priceAdjust.portfolio[data?.date] &&  priceAdjust.portfolio[data?.date].minStay;
  const ifGroupMinOver =
    priceAdjust.groups[activeGroup] &&
    priceAdjust.groups[activeGroup][data?.date] &&
    priceAdjust.groups[activeGroup][data?.date].minStay;

  if (level === 'property') {
    if (ifProMinOver){
      return priceAdjust.property[activeProperty][data?.date].minStay;
    }

    if (ifMarPropertyMinOver) {
      return priceAdjust.market[data?.market][data?.date].minStay;
    }

    if (ifPorfMinOver) {
      return priceAdjust.portfolio[data?.date].minStay;
    }
  };

  if (level === 'market') {
    if (ifMarMinOver) {
      return priceAdjust.market[activeMarket][data?.date].minStay;
    }

    if (ifPorfMinOver) {
      return priceAdjust.portfolio[data?.date].minStay;
    }
  }

  if (level === 'group') {
    if (ifGroupMinOver) {
      return priceAdjust.groups[activeGroup][data?.date].minStay
    }
  }
  
  if (level === 'portfolio') {
    if (ifPorfMinOver) {
      return priceAdjust.portfolio[data?.date].minStay;
    }
  }

  return null;

};

export const getOverridePrice = (priceAdjust, activeProperty, data) => {
  if (priceAdjust.property[activeProperty] && priceAdjust.property[activeProperty][data?.date] &&  priceAdjust.property[activeProperty][data?.date].override) {
    return priceAdjust.property[activeProperty][data?.date].override;
  }
  return null;
}

export const getMultiplier = (priceAdjust, activeMarket, activeGroup, activeProperty, adjustmentMenu, data) => {
  
  const portfolioTooltip = !isUndefined(priceAdjust?.portfolio[data?.date]?.multiplier) ? priceAdjust?.portfolio[data?.date]?.multiplier : adjustmentMenu === 'portfolio' ? data?.multiplier : data?.multiplierPortfolio ;
  const marketTooltip = priceAdjust.market[activeMarket] ? !isUndefined(priceAdjust?.market[activeMarket][data?.date]?.multiplier) ? priceAdjust?.market[activeMarket][data?.date]?.multiplier : data?.multiplier : data?.multiplier ;
  const propertyMarketTooltip =  priceAdjust.market[data?.market] ? !isUndefined(priceAdjust.market[data?.market][data?.date]?.multiplier) ? priceAdjust.market[data?.market][data?.date]?.multiplier : data?.multiplierMarket : data?.multiplierMarket;
  const propertyTooltip = priceAdjust.property[activeProperty] ? !isUndefined(priceAdjust.property[activeProperty][data?.date]?.multiplier) ?  priceAdjust.property[activeProperty][data?.date]?.multiplier : data?.multiplier  :  data?.multiplier;

  const groupTooltip = priceAdjust.groups[activeGroup]
    ? !isUndefined(priceAdjust?.groups[activeGroup][data?.date]?.multiplier)
      ? priceAdjust?.groups[activeGroup][data?.date]?.multiplier
      : data?.multiplier
    : data?.multiplier;

    const groupPropertyTooltip = data?.multiplierGroup;
  

  const getMultiplierValue = {
    portfolio: Number(portfolioTooltip),
    market: Number(portfolioTooltip) + Number(marketTooltip) + (Number(data?.multiplerEvent) || 0),
    property: Number(portfolioTooltip) + Number(propertyMarketTooltip) + (Number(groupPropertyTooltip) || 0) + Number(propertyTooltip) + (Number(data?.multiplerEvent) || 0),
    group:  (Number(portfolioTooltip) || 0) + Number(groupTooltip),
  };

  return getMultiplierValue[adjustmentMenu];
};

export const getMultiplierLogic = (value) => {
  if (value > 100) {
    return 100;
  }

  if (value < -100) {
    return -100
  }

  return value;
};