import React, { useCallback, useEffect, useState, createContext } from 'react';
import styled, { css } from 'styled-components';
import { LayoutBody } from 'components/ReusableComponents/Layout/LayoutBody';
import { lightScrollbar, darkScrollbar } from '../../components/ReusableComponents/Scrollbar/Scrollbar';
import PriceAdjustmentFilter from './components/PriceAdjustmentFilter';
import PriceAdjustmentMenu from './components/PriceAdjustmentMenu';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { now } from '../../constants/GlobalConstant';
import { useDispatch, useSelector } from 'react-redux';
import Paywall from './PriceAdjustmentPaywall';
import { getPropertyPricing, getPropertyPricingRules } from '../../appRedux/actions';
import PriceTableGraphSwitch from './components/PriceTableGraphSwiitch';
import { formatDate } from '../../util/helper';

const AdjustmentBody = styled(LayoutBody)`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  height: calc(100vh - 72px);
  gap: 16px;
  ${({ isPropertyCSS }) => isPropertyCSS};
  ${({ theme }) => (theme.isDefault ? lightScrollbar : darkScrollbar)};
`;

export const PriceAdjustContext = createContext("");

const PriceAdjustment = ({
  isProperty,
  propertyMonthYear,
  propertyValue,
  chosenDates,
  setPropertyMonthYear,
}) => {
  const [monthYear, setMonthYear] = useState(now);
  const [adjustmentMenu, setAdjustmentMenu] = useState('portfolio');
  const [showPriceAdjustGraph, setShowPriceAdjustGraph] = useState(true);
  const [activeProperty, setActiveProperty] = useState('');
  const [activeMarket, setActiveMarket] = useState('');
  const [activeGroup, setActiveGroup] = useState('');
  const [selectedDates, setSelectedDates] = useState([]);
  const [priceAdjust, setPriceAdjust] = useState({ portfolio: {}, market: {},  groups: {}, property: {} });
  const [minMaxPrice, setMinMaxPrice] = useState({});
  const [farOutPrice, setFarOutPrice] = useState({});
  const [stayRules, setStayRules] = useState({ portfolio: {}, market: [], group: [], property: [] });
  const [graphMultiplier, setGraphMultiplier] = useState(true);

  const dispatch = useDispatch();
  const location = useLocation();
  const { state } = location;

  const subscriptionPlan = useSelector(
    (state) => state.users?.authUserDetails?.subscription?.plan
  );

  const { date, propertyId, marketKey, groupId, pushedDates, actionable } = state || {};

  useEffect(() => {
    if (propertyId?.length) {
      setActiveProperty(propertyId);
      setAdjustmentMenu('property');
    }

    if (marketKey?.length) {
      setActiveMarket(marketKey);
      setAdjustmentMenu('market');
    }

    if (groupId?.length) {
      setActiveGroup(groupId);
      setAdjustmentMenu('group');
    }

    if (date) {
      setMonthYear(date);
      if (actionable) {
        setSelectedDates((prev) => [...prev, formatDate(date)]);
      }
    }

    if (pushedDates?.length) {
      setSelectedDates(pushedDates);
    }

    window.history.replaceState({}, '');
  }, [propertyId, marketKey, groupId, actionable, setAdjustmentMenu, date, pushedDates]);

  useEffect(() => {
    if (isProperty) {
      if (propertyValue && propertyValue.length) setActiveProperty(propertyValue);
      setAdjustmentMenu('property');
      const propertyDate = propertyMonthYear || now;
      const month = propertyDate.getMonth() + 1;
      const year = propertyDate.getFullYear();
      dispatch(getPropertyPricing({ month, year, propertyId: propertyValue || '' }));
      dispatch(getPropertyPricingRules({ params: { listingId: propertyValue || '' } }));
      if (chosenDates) setSelectedDates(chosenDates);
      if (propertyMonthYear) setMonthYear(propertyMonthYear);
    }
  }, [isProperty, propertyValue, propertyMonthYear, chosenDates, dispatch]);

  const setMonthYearVal = useCallback((value) => {
    setMonthYear(value);
  }, []);

  const setAdjustmentMenuState = useCallback((value) => {
    setAdjustmentMenu(value);
  }, []);

  const emptySelectedDates = useCallback(() => {
    setSelectedDates([]);
  }, []);

  const priceAdjustToggle = useCallback(() => {
    setShowPriceAdjustGraph((prev) => !prev);
  }, []);

  const isPropertyCSS = css`
    padding: 12px 0 0 0;
  `;

  return (
    <PriceAdjustContext.Provider
      value={{
        selectedDates,
        setSelectedDates,
        priceAdjust,
        setPriceAdjust,
        monthYear,
        setPropertyMonthYear,
        adjustmentMenu,
        activeMarket,
        activeProperty,
        activeGroup,
        setActiveGroup,
        minMaxPrice,
        setMinMaxPrice,
        stayRules,
        setStayRules,
        showPriceAdjustGraph,
        graphMultiplier,
        setGraphMultiplier,
        farOutPrice,
        setFarOutPrice,
        isProperty,
      }}
    >
      <>
        {!['RevenueOS', 'Consultancy', 'Enterprise'].includes(subscriptionPlan) ? (
          <Paywall />
        ) : (
          <AdjustmentBody isPropertyCSS={isProperty && isPropertyCSS}>
            {isProperty ? (
              <PriceTableGraphSwitch
                priceAdjustToggle={priceAdjustToggle}
                showPriceAdjustGraph={showPriceAdjustGraph}
              />
            ) : (
              <PriceAdjustmentFilter
                activeProperty={activeProperty}
                activeMarket={activeMarket}
                activeGroup={activeGroup}
                setActiveProperty={setActiveProperty}
                setActiveMarket={setActiveMarket}
                setActiveGroup={setActiveGroup}
                adjustmentMenu={adjustmentMenu}
                setAdjustmentMenuState={setAdjustmentMenuState}
                emptySelectedDates={emptySelectedDates}
                propertyId={activeProperty}
                monthYear={monthYear}
                setMonthYearVal={setMonthYearVal}
              />
            )}
            <PriceAdjustmentMenu
              adjustmentMenu={adjustmentMenu}
              monthYear={monthYear}
              setMonthYear={setMonthYearVal}
              propertyId={activeProperty}
              isProperty={isProperty}
              activeMarket={activeMarket}
              activeProperty={activeProperty}
              activeGroup={activeGroup}
            />
          </AdjustmentBody>
        )}
      </>
    </PriceAdjustContext.Provider>
  );
};

export default PriceAdjustment;
