import React, { useMemo } from 'react';
import GoalPlanningTable from './GoalPlanningTable';
import styled from 'styled-components';

const StyledTable = styled(GoalPlanningTable)`
  .ant-table-thead > tr > th {
    background-color: #E8E8E8 !important;
    padding: 8px 16px;
  }

  .ant-table-wrapper {
    width: 100%;
    margin-top: 2px;

    .ant-table-tbody > tr > td {
      padding: 8px 0;
    }

    .ant-form-item {
      margin-bottom: 0;
    }
  }

  .ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table, .ant-table-tbody > tr > td > .ant-table-expanded-row-fixed > .ant-table-wrapper:only-child .ant-table {
    margin: 0;
  }
`;

const ExpandedPropertyRowRender = (record, datePickerVal, currency) => {
  const currentYear = datePickerVal.year();
  const previousYear = currentYear - 1;

  const formatAmount = (number) => {
    return number.toLocaleString(undefined, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  };

  const currentYearData = {
    performance: 'Current',
    key: `${currentYear}`,
    occupancy: `${record.currentYearOccupancy ? (record.currentYearOccupancy * 100).toFixed(0) : 0}%`,
    adr: `${currency}${record.currentYearAdr ? formatAmount(record.currentYearAdr) : 0}`,
    rent: `${currency}${record.currentYearTotalRent ? formatAmount(record.currentYearTotalRent) : 0}`,
    revenue: `${currency}${record.currentYearTotalRevenue ? formatAmount(record.currentYearTotalRevenue) : 0}`,
    revpar: `${currency}${record.currentYearRevpar ? formatAmount(record.currentYearRevpar) : 0}`,
    inventory: record.inventory ? record.inventory : 0,
  };

  const lastYearData = {
    performance: 'Last',
    key: `${previousYear}`,
    occupancy: `${record.lastYearOccupancy ? (record.lastYearOccupancy * 100).toFixed(0) : 0}%`,
    adr: `${currency}${record.lastYearAdr ? formatAmount(record.lastYearAdr) : 0}`,
    rent: `${currency}${record.lastYearTotalRent ? formatAmount(record.lastYearTotalRent) : 0}`,
    revenue: `${currency}${record.lastYearTotalRevenue ? formatAmount(record.lastYearTotalRevenue) : 0}`,
    revpar: `${currency}${record.lastYearRevpar ? formatAmount(record.lastYearRevpar) : 0}`,
    inventory: record.inventory ? record.inventory : 0,
  };

  const columns = [
    {
      title: 'PERFORMANCE',
      align: 'right',
      dataIndex: 'performance',
      key: 'performance',
      width: '290px',
    },
    {
      title: 'YEAR',
      align: 'left',
      dataIndex: 'key',
      key: 'key',
      width: '200px',
      render: (text, data) => <div style={{ color: data.performance === 'Current' ? '#1bb3cd' : '#88a7c7' }}>{text}</div>,
    },
    {
      title: 'OCCUPANCY',
      align: 'right',
      dataIndex: 'occupancy',
      key: 'occupancy',
      width: '200px',
      render: (text, data) => <div style={{ color: data.performance === 'Current' ? '#1bb3cd' : '#88a7c7' }}>{text}</div>,
    },
    {
      title: 'ADR',
      align: 'right',
      dataIndex: 'adr',
      key: 'adr',
      width: '200px',
      render: (text, data) => <div style={{ color: data.performance === 'Current' ? '#1bb3cd' : '#88a7c7' }}>{text}</div>,
    },
    {
      title: 'RENT',
      align: 'right',
      dataIndex: 'rent',
      key: 'rent',
      width: '200px',
      render: (text, data) => <div style={{ color: data.performance === 'Current' ? '#1bb3cd' : '#88a7c7' }}>{text}</div>,
    },
    {
      title: 'REVENUE',
      align: 'right',
      dataIndex: 'revenue',
      key: 'revenue',
      width: '200px',
      render: (text, data) => <div style={{ color: data.performance === 'Current' ? '#1bb3cd' : '#88a7c7' }}>{text}</div>,
    },
    {
      title: 'REVPAR',
      align: 'right',
      dataIndex: 'revpar',
      key: 'revpar',
      width: '200px',
      render: (text, data) => <div style={{ color: data.performance === 'Current' ? '#1bb3cd' : '#88a7c7' }}>{text}</div>,
    },
    {
      title: 'INVENTORY',
      align: 'right',
      dataIndex: 'inventory',
      key: 'inventory',
      width: '200px',
      render: (text, data) => <div style={{ color: data.performance === 'Current' ? '#1bb3cd' : '#88a7c7' }}>{text}</div>,
    },
  ];

  const dataSource = [currentYearData, lastYearData];

  return (
    <StyledTable
      columns={columns}
      dataSource={dataSource}
      pagination={false}
      showHeader={true}
      rowKey="key"
    />
  );
};

export default ExpandedPropertyRowRender;
