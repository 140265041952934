import { Tooltip } from 'antd';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { BodyCSS } from '../../../../../../components/ReusableComponents/Card-Body/BodyCSS';
import { PriceAdjustContext } from '../../../../PriceAdjustment';
import { BodyText } from '../../../../../../components/ReusableComponents/Text/Text';
import { isUndefined } from 'lodash';

const SignDiv = styled.div`
  width: 100%;
  height: 26px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  justify-content: center;
`;

const Middleborder = styled.div`
  width: 0.5px;
  height: 100%;
  position: absolute;
  border-right: 0.5px solid black;
  left: 0;
  right: 0;
  margin: auto;
  border-style: dotted;
`;

const PurpleDiv = styled.div`
  width: 50%;
  height: 16px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;  

const PortfolioPositive = styled.div`
  width: ${({colorWidth}) => colorWidth ? colorWidth : '100%'};
  background: #5BD7FF;
`;

const MarketPositive = styled.div`
  width: ${({colorWidth}) => colorWidth ? colorWidth : '100%'};
  background: #00C1FE;
`;

const EventPositive = styled.div`
  width: ${({colorWidth}) => colorWidth ? colorWidth : '100%'};
  background: #FCA484;
`;

const PropertyPositive = styled.div`
  width: ${({colorWidth}) => colorWidth ? colorWidth : '100%'};
  background: #15AAE9;
`;

const GroupPositive = styled.div`
  width: ${({colorWidth}) => colorWidth ? colorWidth : '100%'};
  background: #FE8BF2;
`;

const BlueDiv = styled(PurpleDiv)`
  justify-content: flex-start;
`;

const PortfolioNegative = styled.div`
  width: ${({colorWidth}) => colorWidth ? colorWidth : '100%'};
  background: #E8D9FB;
`;

const MarketNegative = styled.div`
  width: ${({colorWidth}) => colorWidth ? colorWidth : '100%'};
  background: #D7BDF8;
`;

const PropertyNegative = styled.div`
  width: ${({colorWidth}) => colorWidth ? colorWidth : '100%'};
  background: #C6A1F5;
`;

const GroupNegative = styled.div`
  width: ${({colorWidth}) => colorWidth ? colorWidth : '100%'};
  background: #A47BE0;
`;

const MutliplierTooltipDiv = styled.div`
  ${BodyCSS}
  width: 150px;
  height: fit-content;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CirclePortfolio = styled.span`
  width: 10px;
  height: 10px;
  background: #5BD7FF;
  border-radius: 100%;
`;


const CirclePortfolioNegative = styled(CirclePortfolio)`
  background: #E8D9FB;
`;


const CircleEvent = styled(CirclePortfolio)`
  background: #FCA484;
`;

const CircleMarket = styled(CirclePortfolio)`
  background: #00C1FE;
`;

const CircleMarketNegative = styled(CirclePortfolio)`
  background: #D7BDF8;
`;

const CircleGroup = styled(CirclePortfolio)`
  background: #FE8BF2;
`;

const CircleGroupNegative = styled(CirclePortfolio)`
  background: #A47BE0;
`;

const CircleProperty = styled(CirclePortfolio)`
  background: #15AAE9;
`;

const CirclePropertyNegative = styled(CirclePortfolio)`
  background: #C6A1F5;
`;


const BodyTextTooltip = styled(BodyText)`
  align-self: flex-start;
  padding: 0 0 0 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 0px 0px 8px 0;
`;


const Multiplier = ({value}) => {
  
  const {
    priceAdjust,
    adjustmentMenu,
    activeMarket,
    activeProperty,
    activeGroup,
  } = useContext(PriceAdjustContext);
  
  const portfolioTooltip = !isUndefined(priceAdjust?.portfolio[value?.date]?.multiplier) ? priceAdjust?.portfolio[value?.date]?.multiplier : adjustmentMenu === 'portfolio' ? value?.multiplier : value?.multiplierPortfolio || 0 ;
  const marketTooltip = priceAdjust.market[activeMarket] ? !isUndefined(priceAdjust?.market[activeMarket][value?.date]?.multiplier) ? priceAdjust?.market[activeMarket][value?.date]?.multiplier : value?.multiplier : value?.multiplier ;
  const propertyMarketTooltip =  priceAdjust.market[value?.market] ? !isUndefined(priceAdjust.market[value?.market][value?.date]?.multiplier) ? priceAdjust.market[value?.market][value?.date]?.multiplier : value.multiplierMarket : value.multiplierMarket;
  const propertyTooltip = priceAdjust.property[activeProperty] ? !isUndefined(priceAdjust.property[activeProperty][value?.date]?.multiplier) ?  priceAdjust.property[activeProperty][value?.date]?.multiplier : value.multiplier  :  value.multiplier;
  const eventTooltip = value.multiplerEvent;
  const groupTooltip = priceAdjust.groups[activeGroup]
  ? !isUndefined(priceAdjust?.groups[activeGroup][value?.date]?.multiplier)
    ? priceAdjust?.groups[activeGroup][value?.date]?.multiplier
    : value?.multiplier
  : value?.multiplier;

  const groupPropertyTooltip = value?.multiplierGroup || 0;

  const portfolioSize = {
    portfolio: `${Math.abs(portfolioTooltip)}%`,
    market: eventTooltip ? `${Math.abs(portfolioTooltip/3)}%` : `${Math.abs(portfolioTooltip/2)}%`,
    property: eventTooltip ?  `${Math.abs(portfolioTooltip/5)}%` : `${Math.abs(portfolioTooltip/4)}%`,
    group: `${Math.abs(portfolioTooltip)/2}%`,
  };
  
  const marketSize = {
    market: eventTooltip ? `${Math.abs(marketTooltip/3)}%` : `${Math.abs(marketTooltip/2)}%`,
    property: eventTooltip ?  `${Math.abs(propertyMarketTooltip/5)}%` : `${Math.abs(propertyMarketTooltip/4)}%`,
  };

  const propertySize = {
    property: eventTooltip ? `${Math.abs(propertyTooltip/5)}%` : `${Math.abs(propertyTooltip/4)}%`,
  };

  const groupSize = {
    property: eventTooltip ? `${Math.abs(groupPropertyTooltip/5)}%` : `${Math.abs(groupPropertyTooltip/4)}%`,
    group: `${Math.abs(groupTooltip) / 2}%`,
  };  

  const eventSize = {
    market: `${Math.abs(eventTooltip/3)}%`,
    property: `${Math.abs(eventTooltip/4)}%`
  };

  // const getMultiplierValue = {
  //   portfolio: portfolioTooltip,
  //   market: portfolioTooltip + marketTooltip,
  //   property: portfolioTooltip + propertyMarketTooltip + propertyTooltip,
  // };

  const showLine = {
    portfolio: portfolioTooltip !== 0,
    market: portfolioTooltip !== 0 || marketTooltip !== 0 || eventTooltip !== 0,
    property: portfolioTooltip !== 0 || propertyMarketTooltip !== 0  || propertyTooltip !== 0 || eventTooltip !== 0 || groupPropertyTooltip !== 0,
    group: portfolioTooltip !== 0 || groupTooltip !== 0,
  }

  const checkIfHasValuePortPro = Math.abs(portfolioTooltip) > 0 || Math.abs(propertyTooltip) > 0;
  const checIfhasMarket = Math.abs(propertyMarketTooltip) > 0 || Math.abs(marketTooltip) > 0;
  const checkIfHasProperty = checkIfHasValuePortPro || Math.abs(propertyTooltip) > 0;

  const checkIfTooltip = {
    portfolio: Math.abs(portfolioTooltip) > 0 ,
    market: checIfhasMarket || Math.abs(portfolioTooltip) > 0 || Math.abs(eventTooltip) > 0,
    property: checkIfHasProperty || checIfhasMarket || Math.abs(eventTooltip) > 0 || Math.abs(groupPropertyTooltip) > 0,
    group: Math.abs(portfolioTooltip) > 0 || Math.abs(groupTooltip) > 0
  }

  return (
    <Tooltip title={ checkIfTooltip[adjustmentMenu] ? 
      <MutliplierTooltipDiv>
        <BodyText margin="8px 0 12px 0"> Multiplier Profile </BodyText>
        <BodyTextTooltip> {portfolioTooltip >= 0 ? <> <CirclePortfolio/>  {`Portfolio: +${portfolioTooltip}`} </> : <><CirclePortfolioNegative /> {`Portfolio: ${portfolioTooltip}`} </> } </BodyTextTooltip>
        {adjustmentMenu === "market" &&  <BodyTextTooltip>  {marketTooltip >= 0 ? <> <CircleMarket/> {`Market: +${marketTooltip}` } </> : <> <CircleMarketNegative /> { `Market: ${marketTooltip}`} </>} </BodyTextTooltip>}
        {adjustmentMenu === "property" &&  <BodyTextTooltip> {propertyMarketTooltip >= 0 ? <><CircleMarket/> {`Market: +${propertyMarketTooltip}`} </>  :<>  <CircleMarketNegative /> {`Market: ${propertyMarketTooltip}`} </>} </BodyTextTooltip>}
        {adjustmentMenu === "property" &&  <BodyTextTooltip> {propertyTooltip >= 0 ? <><CircleProperty/> {`Property: +${propertyTooltip}`} </>   : <> <CirclePropertyNegative /> {`Property: ${propertyTooltip}`} </>}  </BodyTextTooltip>}
        {adjustmentMenu === "group" && (
          <BodyTextTooltip>
            {groupTooltip >= 0 ? (
              <>
                <CircleGroup /> 
                {`Group: +${groupTooltip}`}
              </>
            ) : (
              <>
                <CircleGroupNegative /> 
                {`Group: ${groupTooltip}`}
              </>
            )}
          </BodyTextTooltip>
        )}
        {adjustmentMenu === "property" &&  <BodyTextTooltip> {groupPropertyTooltip >= 0 ? <><CircleGroup/> {`Group: +${groupPropertyTooltip}`} </>  :<><CircleGroupNegative /> {`Group: ${groupPropertyTooltip}`} </>} </BodyTextTooltip>}
        {eventTooltip ? <BodyTextTooltip> {eventTooltip >= 0 ? <><CircleEvent/> {`Event: +${eventTooltip}`} </>   : <> <CircleEvent /> {`Event: ${eventTooltip}`} </>}  </BodyTextTooltip> : null}
      </MutliplierTooltipDiv>
      : 
      null
    } >
      <SignDiv>
        <PurpleDiv>
          {propertyTooltip < 0 && adjustmentMenu === "property" ?  <PropertyNegative colorWidth={propertySize[adjustmentMenu]}/> : null}
          {propertyMarketTooltip < 0  && adjustmentMenu === 'property' ? <MarketNegative colorWidth={marketSize[adjustmentMenu]}/> : null}
          {marketTooltip < 0  && adjustmentMenu === 'market' ? <MarketNegative colorWidth={marketSize[adjustmentMenu]}/> : null}
          {groupTooltip < 0  && adjustmentMenu === 'group' ? <GroupNegative colorWidth={groupSize[adjustmentMenu]}/> : null}
          {portfolioTooltip < 0 && <PortfolioNegative colorWidth={portfolioSize[adjustmentMenu]}/>}
          {groupPropertyTooltip < 0  && adjustmentMenu === 'property' ? <GroupNegative colorWidth={groupSize[adjustmentMenu]}/> : null}
          {eventTooltip < 0 && <EventPositive colorWidth={eventSize[adjustmentMenu]}/>}
        </PurpleDiv>
        <BlueDiv>
          {propertyTooltip > 0 && adjustmentMenu === "property" ?  <PropertyPositive colorWidth={propertySize[adjustmentMenu]}/> : null}
          {propertyMarketTooltip > 0  && adjustmentMenu === 'property' ? <MarketPositive colorWidth={marketSize[adjustmentMenu]}/> : null}
          {marketTooltip > 0  && adjustmentMenu === 'market' ? <MarketPositive colorWidth={marketSize[adjustmentMenu]}/> : null}
          {groupTooltip > 0  && adjustmentMenu === 'group' ? <GroupPositive colorWidth={groupSize[adjustmentMenu]}/> : null}
          {portfolioTooltip > 0 && <PortfolioPositive colorWidth={portfolioSize[adjustmentMenu]}/>}
          {groupPropertyTooltip > 0  && adjustmentMenu === 'property' ? <GroupPositive colorWidth={groupSize[adjustmentMenu]}/> : null}
          {eventTooltip > 0 && <EventPositive colorWidth={eventSize[adjustmentMenu]}/>}
        </BlueDiv>
        {showLine[adjustmentMenu] && <Middleborder/>}
      </SignDiv>
    </Tooltip>
  )
}

export default Multiplier;