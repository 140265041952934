import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { getEventsTable } from '../../../../appRedux/actions'
import styled from 'styled-components'
import { Select } from 'antd'
import SearchBox from '../../../../components/SearchBox'
import DatePickerWithFunction from '../../../../components/ReusableComponents/DatePicker/DatePickerWithFunction'
import moment from 'moment'

const { Option } = Select;


const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Left = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;


const StyledButton = styled.button`
  ${({ theme }) => `border: 1px solid ${theme.quibbleBlue}`};
  background: white;
  padding: 10px 10px;
  cursor: pointer;
  border-radius: 4px;
`;


const EventHeader = ({ setSearchString }) => {
  const markets = useSelector((state) => state.users.authUserDetails.cityList);

  const [searchString, setSearchStringLocal] = useState(''); 
  const [type, setType] = useState('all');
  // const [scope, setScope] = useState('market');
  const [selectedMarket, setSelectedMarket] = useState('')
  const history = useHistory();
  const dispatch = useDispatch();

  const [selectedOption, setSelectedOption] = useState('Year');

  const now = new Date();
  const [monthYear, setMonthYear] = useState(now);



  useEffect(() => {    
    const storedMonthYear = localStorage.getItem('monthYear');
    if (storedMonthYear) {
      setMonthYear(new Date(storedMonthYear));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('monthYear', monthYear.toISOString());
  }, [monthYear]);

  useEffect(() => {
    const month = monthYear.getMonth() + 1;
    const year = monthYear.getFullYear();

    const params = {
      year
    };

    if (selectedOption !== 'Year') {
      params.month = month;
    }

    if (type !== 'all') {
      params.type = type;
    }

    if (selectedMarket !== '') {
      params.markets = selectedMarket;
    }

    dispatch(getEventsTable({ params }));
  }, [dispatch, monthYear, selectedMarket, type, selectedOption]);

  const handleTypeChange = (selectedValue) => {

    if (selectedValue === 'all') {
      setType('all');
    }

    if (selectedValue === 'event') {
      setType('event');
    }

    if (selectedValue === 'holiday') {
      setType('holiday');
    }
  }

  const handleDateChange = (newDate) => {
    const newMonthYear = new Date(newDate);
    setMonthYear(newMonthYear);
    localStorage.setItem('monthYear', newMonthYear.toISOString());
  };

  const AddEvent = () => {
    history.push('/revenue/events-pricing/new');
  }

  const handleSearchChange = (e) => {
    setSearchString(e.target.value);
    setSearchStringLocal(e.target.value);
  }

  const handleMarketChange = (selectedMarket) => {
    setSelectedMarket(selectedMarket);
    // dispatch(getEventsTable({params:{month, year, markets:selectedMarket}}))
  }

  return (
    <Header>
      <Left>
        <Select value={selectedOption} onChange={(selectedValue) => { setSelectedOption(selectedValue) }}>
          <Option value="Year">Year</Option>
          <Option value="Month">Month</Option>
        </Select>

        {
          selectedOption === 'Year' ? (
            <DatePickerWithFunction picker="year" format={"yyyy"} value={moment(monthYear)} onChange={handleDateChange} style={{ height: '36px' }} />
          ) : (
            <DatePickerWithFunction picker="month" format={"MMMM"} value={moment(monthYear)} onChange={handleDateChange} style={{ height: '36px' }} />
          )
        }

        <Select style={{ width: '200px' }} value={type} onChange={handleTypeChange}>
          <Option value="all">All Types</Option>
          <Option value="event">Event</Option>
          <Option value="holiday">Holiday</Option>
        </Select>

        
        {/* <Select style={{ width: '100px' }} value={scope} onChange={handleTypeChange}>
          <Option value="market">Market</Option>
          <Option value="property">Property</Option>
        </Select> */}

        <Select style={{ width: '200px' }} defaultValue="All Markets" onChange={handleMarketChange}>
          <Option>All Markets</Option>
          {
            markets && markets.map((market) => (
              <Option key={market.city}>
                {market.city}
              </Option>
            ))
          }
        </Select>

        <div style={{ backgroundColor: 'white', color: 'black', width: '300px' }}>
          <SearchBox value={searchString} onChange={handleSearchChange} placeholder="Search" />
        </div>

      </Left>

      <StyledButton onClick={AddEvent}>Add Event</StyledButton>
    </Header>


  )
}

export default EventHeader