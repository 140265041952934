import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { LayoutBody } from 'components/ReusableComponents/Layout/LayoutBody';
import GroupDetailFilter from './components/GroupDetailFilter';
import GroupDescription from './components/GroupDescription';
import { darkScrollbar, lightScrollbar } from '../../../components/ReusableComponents/Scrollbar/Scrollbar';
import { now } from '../../../constants/GlobalConstant';
import GroupDetailsTable from './components/GroupDetailsTable';


const GroupDetailsBody = styled(LayoutBody)`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  height: calc(100vh - 72px);
  ${({ theme }) => (theme.isDefault ? lightScrollbar : darkScrollbar)};
`;

const GroupDetails = () => {

  const [monthYear, setMonthYear] = useState(now);
  const [searchString, setSearchString] = useState('');
  const location = useLocation();
  const { state } = location;

  const setMonthYearVal = useCallback((value) => {
    setMonthYear(value);
  }, []);

  const setSearchStringFunc = useCallback((value) => {
    setSearchString(value)
  }, []);

  return (
    <GroupDetailsBody>
      <GroupDetailFilter monthYear={monthYear} group={state} setSearchStringFunc={setSearchStringFunc} setMonthYearVal={setMonthYearVal}/>
      <GroupDescription monthYear={monthYear} />
      <GroupDetailsTable searchString={searchString} monthYear={monthYear} />
    </GroupDetailsBody>
  )
}

export default GroupDetails;
