import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Checkbox } from 'antd';
import { BodyText } from '../../../../components/ReusableComponents/Text/Text';
import RenderBar from '../../sharedComponents/MarketPropertyTable/RenderBar';
import ExtendRenderBar from '../../sharedComponents/MarketPropertyTable/ExtendRenderBar';
import StarRating from '../../../../components/ReusableComponents/Rating/StarRatings';
import { CaretDownOutlined } from '@ant-design/icons';
import QuibbleTable from 'components/ReusableComponents/Table/QuibbleTable';
import PropertyTableFilter from '../../sharedComponents/PropertyTableFilter/PropertyTableFilter';
import { showName } from '../../../../util/helper';
import AdjustQueueMenu from '../../../../components/ReusableComponents/TableMenus/AdjustQueueMenu';
import { useLocation } from 'react-router-dom';


const MarketDetailsTableDiv = styled.div`
  width: 100%;
  height: fit-content;
  max-width: 1800px;
  max-height: 706px;
  margin: 16px 0 0 0;
`;

const TableDiv = styled.div`
  min-width: 1776px;
  height: fit-content;
  max-height: 662px;`
;

const NameLink = styled(BodyText)`
  margin: 0px;
  &:hover {
    color: ${({theme}) => theme.quibbleBlue};
  }
`;

const ExtendNameLink = styled(NameLink)`
  color: ${({theme}) => theme.expandedTableFont };
`;

const StyledCaret = styled(CaretDownOutlined)`
  background-color: ${({caretActive, theme}) => caretActive && theme.quibbleBlue };
  color: ${({caretActive}) => caretActive && 'white' };
  padding:  ${({caretActive}) => caretActive && '3px 6px'};
  border-radius: 50%;
  

`;

const CheckboxStyle = styled(Checkbox)`
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${({ checked }) => checked && 'transparent'};
    border-color: ${({ checked }) => checked && 'black'};

  }

  .ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: ${({ checked, theme }) => checked && theme.quibbleBlue};
  }
`;


const GroupDetailsTable = ({monthYear, searchString}) => {

  const location = useLocation();
  const { state } = location;
  const { groupId } = state || {};

  const [ data, setData ] = useState([]);

  const { propertyGroupTable, isFetchingPropertyGroupDetails } = useSelector(({ property }) => property);
  
  const [activeFilter, setActiveFilter] = useState('');
  const [checkedValues, setCheckedValues] = useState([]);

  useEffect(() => {
    if (propertyGroupTable) {
      setData(propertyGroupTable)
    }
  }, [propertyGroupTable]);

  const marketPropertyTableColumns =  [
    {
      label: 'property' ,
      width: '220px',
      align: 'left',
      customRender: (data) => (<Link to={{ pathname: "/market-analytics/group-property-details", state: {date: monthYear, propertyId: data?.id, groupId }}}><NameLink>{showName(data?.key, 22)}</NameLink></Link>),
      extendDataIndex: 'key',
      extendCustomRender: (data) => (<ExtendNameLink>{showName(data, 22)}</ExtendNameLink>),
    },
    {
      label: 'occupancy',
      width: '332px',
      align: 'left',
      customRender: (data) => <RenderBar tooltipLabel='Occupancy' current={data?.currentYearOccupancy}  forecast={data?.forecastOccupancy} market={data?.marketOccupancy} type="occupancy"/>,
      extendDataIndex: 'lastYearOccupancy',
      extendCustomRender: (data) => <ExtendRenderBar data={data}  type="occupancy"/>,

    },
    {
      label: 'occupancy gap',
      width: '148px',
      // align: 'right',
      dataIndex: 'currentYearOccupancyGap',
      customRender: (data) => (`${(data * 100).toFixed(0)}%`),

    },
    {
      label: 'adr',
      width: '332px',
      align: 'left',
      customRender: (data) => <RenderBar tooltipLabel='ADR' current={data?.currentYearAdr}  forecast={data?.forecastAdr} />,
      extendDataIndex: 'lastYearAdr',
      extendCustomRender: (data) => <ExtendRenderBar data={data}/>,
      
    },
    {
      label: 'revenue',
      width: '332px',
      align: 'left',
      customRender: (data) => <RenderBar tooltipLabel='Revenue' current={data?.currentYearTotalRevenue}  forecast={data?.lastYearTotalRevenue}/>,
      extendDataIndex: 'lastYearTotalRevenue',
      extendCustomRender: (data) => <ExtendRenderBar data={data}/>,

    },
    {
      label: 'reviews',
      width: '156px',
      align: 'left',
      customRender:(data) => <StarRating yellowLabel={data.averageMarketRating} purpleLabel={data.averageRating}/>,

    },
    {
      label: 'blocked days',
      width: '138px',
      // align: 'right',
      dataIndex: 'currentYearBlockedDaysShare',
      customRender: (data) => (`${(data * 100).toFixed(0)}%`),
      extendDataIndex: 'lastYearBlockedDaysShare',
      extendCustomRender: (data) => (`${(data * 100).toFixed(0)}%`),
    },
  ];


  const onCheckBoxValues = useCallback((values) => {
    setCheckedValues(values);
  }, []);

  const onFilterClick = useCallback((value) => {
    setActiveFilter((prev) => prev === value ? '' : value);
  }, []);

  const marketPropertySearch = data?.filter((data) => data?.key?.toLowerCase().includes(searchString));

  const filteredProperties = useMemo(() => {
    return {
      stars: marketPropertySearch?.filter((data) => data?.quadrant === 'star'),
      overpriced: marketPropertySearch?.filter((data) => data?.quadrant === 'overpriced'),
      underpriced: marketPropertySearch?.filter((data) => data?.quadrant === 'underpriced'),
      productIssues: marketPropertySearch?.filter((data) => data?.quadrant === 'issue'),
    }
  }, [marketPropertySearch]);

  const tableData = filteredProperties[activeFilter] || marketPropertySearch;

  const checkedValuesData = checkedValues.map((data) => {
    return tableData[data];
  });

  return (
    <MarketDetailsTableDiv>
      <AdjustQueueMenu monthYear={monthYear}  adjustQueueData={checkedValuesData} checkedRows={checkedValues.length}/>
      <PropertyTableFilter
        onFilterClick={onFilterClick}
        starsLength={filteredProperties['stars']?.length}
        underpricedLength={filteredProperties['underpriced']?.length}
        overpricedLength={filteredProperties['overpriced']?.length}
        productIssuesLength={filteredProperties['productIssues']?.length}
        activeButton={activeFilter}
        width='1776px'
      />
      <TableDiv>
        <QuibbleTable
          columns={marketPropertyTableColumns}
          data={tableData}
          expandable={true}
          withCheckBox={true}
          pagination={true}
          paginationSize={10}
          isLoading={isFetchingPropertyGroupDetails}
          checkedRows={checkedValues}
          onCheckBoxChange={onCheckBoxValues} 
          monthYear={monthYear}
          type="property"
          columnFilter={true}
          />
      </TableDiv>
    </MarketDetailsTableDiv>
  )
}

export default GroupDetailsTable;