import React, { Fragment } from "react";
import styled from "styled-components";
import { isEmpty } from "lodash";


import SkLoaderOver from "../../../../../../components/ReusableComponents/SkeletonLoader/SkLoaderOver";
import quibbleRm from 'assets/images/quibblerm.png';
import { DetailsDiv, EmptyBodyText } from "../../../../sharedComponents/CommonCSS";
import { BodyText, BodyTitle } from "../../../../../../components/ReusableComponents/Text/Text";
import RenderBar from "../../../../sharedComponents/MarketPropertyTable/RenderBar";
import StarRating from "../../../../../../components/ReusableComponents/Rating/StarRatings";

const GroupDetailsDiv = styled(DetailsDiv)`
  height: 200px;
`;

const ImageDiv = styled.img`
  min-width: 230px;
  min-height: 155px;
  border-radius: 6px;
  border: ${ ({ border }) => border };
`;

const GroupInfoDiv = styled.div`
  width: fit-content;
  height: auto:
  display: flex;
  flex-direction: column;
  margin: 0 0 0 16px;
`;

const GroupInfoRow = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 16px 0 0 0;
  gap: 16px;
`;

const GroupInfoItem = styled.div`
  width: 220px;
  display: flex;
  flex-direction: column;
`;

const GroupPropertyLabel = styled(BodyText)`
  font-size: ${({theme}) => theme.s3};
  margin: 0 0 8px 0;
`;

const ValueSpan = styled(BodyText)`
  color: ${({theme}) => theme.quibbleFontGrey};
  margin: 0px;
`;


const GroupInfo = ({marketDetails, isFetchingMarketDetails }) => {

  const {key, currentYearAdr, forecastAdr, currentYearOccupancy,
     forecastOccupancy, marketOccupancy, averageMarketRating,
     currentYearOccupancyGap, currentYearBlockedDaysShare,
     averageRating, currentYearRevpar, forecastRevpar, propertyGroup}= marketDetails;

  return (
    <GroupDetailsDiv>
      {isEmpty(marketDetails) ? <EmptyBodyText>No Data Found</EmptyBodyText> :
        <Fragment>
          <ImageDiv src={propertyGroup?.imageUrl || quibbleRm} />
          <GroupInfoDiv >
            <BodyTitle>{key}</BodyTitle>
            <GroupInfoRow>
              <GroupInfoItem >
                <GroupPropertyLabel>
                  ADR
                </GroupPropertyLabel>
                <RenderBar current={currentYearAdr} forecast={forecastAdr}/>
              </GroupInfoItem>
              <GroupInfoItem >
                <GroupPropertyLabel>
                  Occupancy
                </GroupPropertyLabel>
                <RenderBar current={currentYearOccupancy}  forecast={forecastOccupancy} market={marketOccupancy} type="occupancy"/>
              </GroupInfoItem>
              <GroupInfoItem >
                <GroupPropertyLabel>
                  Rev PAR
                </GroupPropertyLabel>
                <RenderBar current={currentYearRevpar}  forecast={forecastRevpar} />
              </GroupInfoItem>
            </GroupInfoRow>
            <GroupInfoRow>
              <GroupInfoItem >
                <GroupPropertyLabel>
                  Reviews
                </GroupPropertyLabel>
                <StarRating yellowLabel={averageMarketRating} purpleLabel={averageRating}/>
              </GroupInfoItem>
              <GroupInfoItem >
                <GroupPropertyLabel>
                  Occupancy Gap
                </GroupPropertyLabel>
                <ValueSpan>{(currentYearOccupancyGap * 100).toFixed(0)}%</ValueSpan>
              </GroupInfoItem>
              <GroupInfoItem >
                <GroupPropertyLabel>
                  Blocked Days
                </GroupPropertyLabel>
                <ValueSpan>{(currentYearBlockedDaysShare * 100).toFixed(0)}%</ValueSpan>
              </GroupInfoItem>
            </GroupInfoRow>
          </GroupInfoDiv>
        </Fragment>}
      <SkLoaderOver isLoading={isFetchingMarketDetails || isEmpty(marketDetails)} type="description" />
    </GroupDetailsDiv>
  )
}

export default GroupInfo;
