import React from "react";
import styled from "styled-components";
import ButtonWithFunction from "../../../../../../components/ReusableComponents/Button/ButtonWithFunction";

const SelectionBody = styled.div`
  width: 100%;
  max-width: 1776px;
  height: 40px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  background: none;
  margin: 0 0 8px 0;
`;

const SelectionCSS = `
  width: 224px;
  height: 36px;
  background: none;
  outline: none;
  color: #697273;
  size: 1rem;
  font-weight: 300;
  border-radius: 2px;
  box-shadow: none;
  border: none;
  transition: background 0.2s linear;

  &:hover, &:focus {
    border: 1px solid #C2ECF3;
    background: none;
    color: #283334;
  }

  &.active {
    background: #C2ECF3;
    color: #283334;
  }
`;

const ModelSelection = ({modelSelection, setModelSelection}) => {

  const onChangeSelection = (value) => {
    setModelSelection(value);
  }

  return (
    <SelectionBody>
      <ButtonWithFunction className={modelSelection === "compEdge" && 'active'} ButtonStyle={SelectionCSS} value={"compEdge"} label={"Comp Edge"} onClickFunc={onChangeSelection} />
      <ButtonWithFunction className={modelSelection === "priceSage" && 'active'} ButtonStyle={SelectionCSS} value={"priceSage"} label={"Price Sage"} onClickFunc={onChangeSelection}/>
    </SelectionBody>
  )
}

export default ModelSelection;