import React, { useEffect, useState, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { Select } from 'antd';
import DatePickerWithFunction from 'components/ReusableComponents/DatePicker/DatePickerWithFunction';
import { FULL_MONTH_NAME } from 'constants/GlobalConstant';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { LeftOutlined } from '@ant-design/icons';
import { getPropertyGroupDetails, getPropertyGroupTable, getPropertyGroupAnalytics, getPropertyGroupPricing, getPropertyGroupPricingRules } from '../../../../appRedux/actions';
import useClickOutsideListener from '../../../../customHooks/useClickOutsideListener';
import { lightScrollbar, darkScrollbar } from "components/ReusableComponents/Scrollbar/Scrollbar";
import searchIcon from 'assets/images/generic/search.svg';
import dollarIcon from 'assets/images/override/dollar.svg';
import { MarketFilterItemDiv, SearchImage, SearchInput } from '../../sharedComponents/MarketPropertyFilter/MarketPropertyFilter';

const { Option } = Select;

const FilterBody = styled.div`
  width: 100%;
  max-width: 1776px;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center; /* Ensure vertical alignment */
`;

const SelectDiv = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 156px; /* Match the width of AdjustmentSelectProperty */
  height: 100%; /* Match the parent height */
  margin-right: 8px;
`;

const AdjustmentSelectProperty = styled(Select)`
  width: 100%;
  height: 48px; /* Match the DatePicker height */
  font-family: Commissioner;

  .rc-virtual-list-holder {
    ${({ theme }) => (theme.isDefault ? lightScrollbar : darkScrollbar)};
  }
`;

const DatePickerStyle = css`
  width: 200px;
  height: 48px;
  border-radius: 4px;
  border: 1px solid #CDCDCD;
  margin: 0;
  font-family: Commissioner;
`;

const ButtonCSS = css`
  border-radius: 4px;
  outline: none;
  border: 1px solid #00BBD2;
  background: #F6F6F6;
  color: #00BBD2;
  text-align: center;
  font-family: Commissioner;
  cursor: pointer;

  &:hover, &.active {
    background: #00BBD2;
    color: white;
  }

  &:disabled {
    border: none;
    background: ${({theme}) => theme.disabledGrey};
    color: ${({theme}) => theme.white};
  }

`;

const SelectButton = styled.button`
  ${ButtonCSS}
  width: 156px;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
`;

const BackButton = styled.button`
  ${ButtonCSS}
  width: 48px;
  height: 48px;
  margin: 0 8px 0 0;
`;

const Boldspan = styled.span`
  font-weight: 600;
`;

const GroupDetailItemDiv = styled(MarketFilterItemDiv)`
  justify-content: flex-end;
  flex-grow: 1;
  gap: 10px;
`;

const GroupDetailSearchDiv = styled(SearchImage)`
  right: 72px;
`;

const DollarButton = styled.button`
  width: fit-content;
  height: fit-content;
  border: none;
  outline: none;
  cursor: pointer;
  background: none;
`;

const GroupDetailFilter = ({ monthYear, setMonthYearVal, group, setSearchStringFunc }) => {
  const { date, groupId } = group || {};
  const { propertyGroupAnalytics, isFetchingPropertyGroupAnalytics } = useSelector(({ property }) => ({ ...property }));
  const [selectedGroup, setSelectedGroup] = useState(groupId ? [groupId] : []);
  const [showSelect, setShowSelect] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const filterPropertyAnalytics = useMemo(() => {
    let filteredListings = propertyGroupAnalytics.filter(
      (item) => item.propertyGroup?.type === "Group"
    );
  
    return filteredListings;
  }, [propertyGroupAnalytics]);

  useEffect(() => {
    if (!groupId) history.push('/home');
  }, [groupId, history]);

  useEffect(() => {
    if (date) {
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      setMonthYearVal(date);
      dispatch(getPropertyGroupDetails({ params: { year, month, propertyGroupId: groupId } }));
      dispatch(getPropertyGroupTable({ params: { year, month, propertyGroupId: groupId } }));
      dispatch(getPropertyGroupAnalytics( { params: { year, month }}));
    }
  }, [date, groupId, dispatch, setMonthYearVal]);

  const onDatePickerChange = (value) => {
    const dateSplit = value.split('-');
    const month = FULL_MONTH_NAME.indexOf(dateSplit[0]) + 1;
    const selectedDate = new Date(dateSplit[1], month - 1, 1);
    setMonthYearVal(selectedDate);
    dispatch(getPropertyGroupDetails({ params: { year: Number(dateSplit[1]), month, propertyGroupId: groupId } }));
    dispatch(getPropertyGroupTable({ params: { year: Number(dateSplit[1]), month, propertyGroupId: groupId } }));
    dispatch(getPropertyGroupAnalytics( { params: { year: Number(dateSplit[1]), month }}));
  };

  const onBackClick = () => {
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const updatedGroups = [...selectedGroup];
    updatedGroups.shift();
    setSelectedGroup(updatedGroups);
    dispatch(getPropertyGroupDetails({ params: { propertyGroupId: updatedGroups[0] } }));
    dispatch(getPropertyGroupTable({ params: { year, month, propertyGroupId: updatedGroups[0] } }));
  };

  const onGroupChange = (value) => {
    setSelectedGroup((prev) => [value, ...prev]);
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
  
    dispatch(getPropertyGroupDetails({ params: { propertyGroupId: value } }));
    dispatch(getPropertyGroupTable({ params: { year, month, propertyGroupId: value } }));
  
    history.push({
      pathname: '/market-analytics/group-details', 
      state: {date: monthYear, groupId},
    })
  
    setShowSelect(false);
  };
  
  const onDollarCLick = () => {
    history.push(
      {
        pathname: "/price-adjustment",
        state: {groupId: selectedGroup[0] || '', date: monthYear},
      },);

      const month = monthYear.getMonth() + 1;
      const year =  monthYear.getFullYear();
      dispatch(getPropertyGroupDetails({ params: { propertyGroupId: selectedGroup[0] } }));
      dispatch(getPropertyGroupPricing({ month, year, propertyGroupId: selectedGroup[0] }));
      dispatch(getPropertyGroupPricingRules({ params: { propertyGroupId: selectedGroup[0] } }));
  };

  const onInputChange = (e) => {
    e.preventDefault();
    const searchString = e.target.value;
    setSearchStringFunc(searchString);
  };

  const { ref } = useClickOutsideListener(() => setShowSelect(false));

  return (
    <FilterBody>
      <BackButton 
        disabled={selectedGroup.length <= 1} 
        onClick={onBackClick}
      >
        <LeftOutlined />
      </BackButton>
      <SelectDiv ref={ref}>
        <AdjustmentSelectProperty
          optionLabelProp="children"
          defaultActiveFirstOption={false}
          onChange={onGroupChange}
          getPopupContainer={(trigger) => trigger.parentNode}
          open={showSelect}
          dropdownMatchSelectWidth={false}
        >
          {filterPropertyAnalytics.map((data) => (
            <Option
              key={data.propertyGroup._id}
              value={data.propertyGroup._id}
              disabled={selectedGroup[0] === data.propertyGroup._id}
            >
              <Boldspan>{data.propertyGroup.name || "Unnamed Group"}</Boldspan>
            </Option>
          ))}
        </AdjustmentSelectProperty>
        <SelectButton
          disabled={isFetchingPropertyGroupAnalytics}
          className={showSelect === true && "active"}
          onClick={() => setShowSelect((prev) => !prev)}
        >
          {isFetchingPropertyGroupAnalytics ? "Fetching Groups..." : "Change Group"}
        </SelectButton>
      </SelectDiv>
      <DatePickerWithFunction
        DatepickerStyle={DatePickerStyle}
        picker="month"
        format="MMMM-YYYY"
        value={moment(monthYear, "MMMM-YYYY")}
        onChangeFunc={onDatePickerChange}
      />
      <GroupDetailItemDiv>
        <SearchInput type="search" placeholder="Search" onChange={onInputChange} />
        <GroupDetailSearchDiv src={searchIcon} alt="search icon" />
        <DollarButton onClick={onDollarCLick}>
          <img src={dollarIcon} alt="dollar icon" width={"40px"} height="40px" />
        </DollarButton>
      </GroupDetailItemDiv>
    </FilterBody>
  );
};

export default GroupDetailFilter;
