import React, { useMemo } from 'react';
import { DescriptionBody } from "../../sharedComponents/CommonCSS";
import GroupInfo from "./subComponents/GroupDescription/GroupInfo";
import { useSelector } from "react-redux";
import styled from "styled-components";
import MarketPropertyMetrics from '../../sharedComponents/MarketPropertyMetrics/MarketPropertyMetrics';

const GroupDescriptionBody = styled(DescriptionBody)`
  height: 200px;
`;

const GroupDescription = ({ monthYear }) => {
  const { propertyGroupDetails, isFetchingPropertyGroupDetails } = useSelector(({ property }) => property);

  const metrics = {
    adr: propertyGroupDetails[0]?.currentYearAdr,
    occ: propertyGroupDetails[0]?.currentYearOccupancy,
    totalRent: propertyGroupDetails[0]?.currentYearTotalRevenue,
  };

  return (
    <GroupDescriptionBody>
      <GroupInfo marketDetails={propertyGroupDetails[0] || {}} isFetchingMarketDetails={isFetchingPropertyGroupDetails}/>
      <MarketPropertyMetrics monthYear={monthYear} metrics={metrics} isFetchingMetrics={isFetchingPropertyGroupDetails} isMarket={true}/>
    </GroupDescriptionBody>
  )
}

export default GroupDescription;
